import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

const List = (props) => {
  return (
    <div className="columns is-multiline has-text-centered">
      {props.items.map((item) => {
        if (!item.id) {
          item.id = item.title
            .replace(/\s+/g, "-")
            .toLowerCase()
            .trim()
        }
        return (
          <div className="column is-half" key={`key-${item.id}`}>
            <div className="card">
              <div
                className="card-content"
                style={{ backgroundColor: item.color }}
              >
                <p className="subtitle is-4">
                  <Link to={item.link}>{item.title}</Link>
                </p>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
List.propTypes = {
  items: PropTypes.array.isRequired,
}

export default List
