import React from "react"
import List from "../components/list"
import Layout from "../components/layout"
import SEO from "../components/seo"

const listItems = [
  { link: "/home", title: "Home Page" },
  { link: "/concentrationgames", title: "See all Concentration Games" },
  { link: "/practicepages", title: "See all Practice Pages" },
  { link: "/speedgrids", title: "See all Speed Grids" },
  { link: "/wordfamiles", title: "See all Word Familes" },
  { link: "/pathpages", title: "See all Path Pages" },
  { link: "/books", title: "See all Books"},
]

const DevPage = () => (
  <Layout>
    <SEO title="Development Menu" />
    <List items={listItems} />
  </Layout>
)

export default DevPage
